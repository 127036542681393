.primary {
  color: $primary !important;
}
.success {
  color: $success !important;
}
.info {
  color: $info !important;
}
.warning {
  color: $warning !important;
}
.danger {
  color: $danger !important;
}
.secondary {
  color: $secondary !important;
}
.white {
  color: $white !important;
}
.gray-5 {
  color: $gray-5;
}
.gray-6 {
  color: $gray-6;
}
.gray-7 {
  color: $gray-7;
}
.gray-8 {
  color: $gray-8;
}
