// Text

// == Text Size
@mixin text-size {
  @for $i from 1 through 50 {
    .text-size-#{$i} {
      font-size: #{$i}px !important;
    }
  }
}
@include text-size;

// == Text Spacing
@mixin text-spacing {
  @for $i from 1 through 10 {
    .text-spacing-#{$i} {
      letter-spacing: #{calc($i / 2)}px !important;
    }
  }
}
@include text-spacing;

// == Text Weight
.text-black,
.text-w800 {
  font-weight: 800 !important;
}
.text-bold,
.text-w700 {
  font-weight: 700 !important;
}

.text-semibold,
.text-w600 {
  font-weight: 600 !important;
}

.text-medium,
.text-w500 {
  font-weight: 500 !important;
}

.text-normal,
.text-w400 {
  font-weight: 400 !important;
}

.text-light,
.text-w300 {
  font-weight: 300 !important;
}

// == Text Format
.text-underline {
  text-decoration: underline;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercse {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}
