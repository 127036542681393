.header {
  background-color: white;
  color: #000;
  height: 90px;
  border-top: solid 1px #f1f2f6;
  border-bottom: solid 1px #f1f2f6;
}

.header-service {
  background-color: white;
  font-weight: 300;

  .inline-header {
    display: inline-flex;
  }

  small {
    font-size: 12px;
  }

  span {
    font-size: 14px;
  }

  .icon-service {
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 15px;
  }
}
