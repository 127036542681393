.icon {
    display: inline-block;
    width: 2em;
    height: 2em;
    font-size: 30px;
    fill: #D7DCE2;
    transition: all 0.2s ease-in-out;
  }
  
  html, body {
    height: 100%;
  }
  
  body {
    background: #ffeff5;
    position: relative;
  }
  
  .rotate {
    animation: rotate-keyframes 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
   }
   
   @keyframes rotate-keyframes {
    from {
     transform: rotate(0deg);
    }
   
    to {
     transform: rotate(360deg);
    }
   }

  .player {
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
  }
  .player.play .player__timeline {
    transform: translateY(-90%);
  }
  .player.play .player__album:after {
    box-shadow: 0px 30px 28px -10px rgba(0, 0, 0, 0.2);
  }
  .player.play .player__album {
    top: -65px;
  }
  .player.play .pause {
    display: inline-block;
  }
  .player.play .play {
    display: none;
  }
  .player__album {
    width: 84px;
    height: 84px;
    border-radius: 50%;
    margin-right: 22px;
    position: relative;
    top: -50px;
    transition: all 0.4s ease-in-out;
  }
  /* .player__album:before {
    content: "";
    width: 18.75px;
    height: 18.75px;
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffeff5;
    border-radius: 50%;
  }
  .player__album:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
  } */
  .player__albumImg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    display: none;
  }
  .player__albumImg.active-song {
    display: block;
  }
  .player__bar {
    /* background: #fff; */
    /* background: rgba(255, 255, 255, 0.35); */
    background: rgba(148, 163, 184, .8);
    padding: 7.5px 18.75px;
    height: 75px;
    /* height: 50px; */
    display: flex;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 30px 56px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 3;
  }
  .player__controls {
    display: flex;
    align-items: center;
  }
  .player__prev {
    transform: rotate(180deg);
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 15px;
    transition: all 0.2s ease-in-out;
    margin-right: 3px;
  }
  .player__prev:hover {
    background: rgba(215, 220, 226,.3);
  }
  .player__prev:hover svg {
    fill: #fff;
  }
  .player__play {
    cursor: pointer;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    transition: all 0.2s ease-in-out;
    margin-right: 3px;
    position: relative;
  }
  .player__play:hover {
    /* background: #D7DCE2; */
    background: rgba(215, 220, 226,.3);
  }
  .player__play:hover svg {
    fill: #fff;
  }
  .player__play svg {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .player__play svg.pause {
    /* display: none; */
  }
  .player__next {
    cursor: pointer;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    transition: all 0.2s ease-in-out;
    margin-left: -8px;
  }
  .player__next:hover {
    background: rgba(215, 220, 226,.3);
  }
  .player__next:hover svg {
    fill: #fff;
  }
  .player__timeline {
    background: #fff6fb;
    /* height: 71.25px; */
    height: 71.25px;
    border-radius: 15px;
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    z-index: 1;
    padding-left: 120px;
    flex-direction: column;
    justify-content: center;
  }
  .player__timelineBar {
    background: #E7E7E7;
    width: 95%;
    height: 4px;
    border-radius: 15px;
    margin-top: 13px;
    position: relative;
  }
  .player #playhead {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
    width: 0;
    height: 100%;
    background: #fd6d94;
  }
  .player__author {
    line-height: 1;
    font-weight: bold;
    margin-bottom: 6px;
    margin-top: 15px;
  }
  .player__song {
    line-height: 1;
    margin: 0;
    font-size: 12px;
    color: #949494;
  }