@font-face {
  font-family: "Akira";
  src: url("../../fonts/akira_expanded/akira-expanded.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/Montserrat/static/Montserrat-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/Montserrat/static/Montserrat-Thin.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/Montserrat/static/Montserrat-Light.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/Montserrat/static/Montserrat-Black.ttf")
    format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/Montserrat/static/Montserrat-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/Montserrat/static/Montserrat-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}