.main {
    background-color: #282c34;
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.button {
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.main h1 {
    color: #fff;
    font-size: 40px;
    text-align: center;
}

.main img {
    width: 100%;
    max-width: 350px;
}

.container {
    display: grid;
    grid-template-columns: 20% 1%;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas: "header header" "title article" "nav article" "footer footer";
}

.sidebar {
    grid-area: nav;
}

.content {
    grid-area: article;
    padding-top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.item {
    padding: 10px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.header nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.header a {
    color: #fff;
    text-decoration: none;
    font-size: 25px;
    margin-left: 20px;
}

.header a:first-of-type {
    margin-left: 0;
}

@media screen and (max-width: 575px) {
    .header {
        justify-content: center;
    }
    .header nav {
        padding-top: 20px;
    }
}


/* Dropdown Button */

.dropbtn {}


/* The container <div> - needed to position the dropdown content */

.dropdown {}


/* Dropdown Content (Hidden by Default) */

.dropdown-content {
    display: none;
    position: absolute;
    background-color: rgba(191, 0, 78, 1);
    box-shadow: rgba(0, 0, 0, 1) 3px 19px 38px, rgba(0, 0, 0, 0.7) 0px 15px 12px;
    /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
    z-index: 1;
}


/* Links inside the dropdown */

.dropdown-content button {
    padding: 6px;
    display: block;
    color: white;

    background-color: rgba(191, 0, 78, 1);
}


/* Show the dropdown menu on hover */

.dropdown:hover .dropdown-content {
    display: block;
    border: 1px solid rgba(191, 0, 78, 1);
    border-bottom: 0px;
    /* border-top: 2px solid rgba(191, 0, 78, 1); */
}

.dropdown-content-button {
    font-weight: bold;
    border: none;
    border-bottom: 2px solid rgba(101, 0, 78, 0.216);
    text-decoration: none;
    width: 100%;
    transition: 0.3s;
}

.dropdown-content-button:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    /* border-bottom: 2px solid #bf004e; */
}


/* Change the background color of the dropdown button when the dropdown content is shown */

.dropdown:hover .dropbtn {
    /* background-color: #3e8e41; */
}