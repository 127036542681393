body {
  min-height: 100vh;
  background-color: #000000;
  font-family: "Akira", "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji",  !important;
  color: #2f3542;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.mh-100 {
  min-height: 100% !important;
}

.montserrat {
  font-family: "Montserrat", sans-serif !important;
  color: #2f3542;
}
.akira {
  font-family: "Akira", sans-serif !important;
  color: white;
}

.pointer {
  cursor: pointer;
}
