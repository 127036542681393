
/* Dropdown Button */
.dropbtn {
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
   
  /* Links inside the dropdown */
  .dropdown-content a {
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {} 